/**
 * A Charcoal Color Scheme
 * 9/13/2016
 * James Johnson
 * Excellent InGenuity LLC
 *
 * 
 */
$charcoal: #545452;
$dark-grey: #3E3F45;
$golden-brown: #8E6D3C;
$light-grey: #C9C8CE;
$shale-grey: #635A58;
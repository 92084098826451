//
// Contextual backgrounds
//

.bg-inverse {
  background-color: $brand-inverse;
}

.bg-faded {
  background-color: $gray-lightest;
}

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $brand-success);

@include bg-variant('.bg-info', $brand-info);

@include bg-variant('.bg-warning', $brand-warning);

@include bg-variant('.bg-danger', $brand-danger);

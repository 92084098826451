.navbar-faith {
	background-color:$primary;
	padding-top: 0;
	padding-bottom: 0;
	.nav-link {
	 	color: $white !important;
	 	font-weight: 500;
	 	font-size: 1.2em;
	 	padding: .5em;
	 	padding-bottom: 1em;
	 	padding-top: 1em;
	}
	.nav-link:hover {
	 	color: $white !important;
	 	background-color: $accent;
	}
	.nav-link .dropdown-toggle:hover {
	 	color: $white;
	 	background-color: $accent;	
	}

	.dropdown-menu {
		background-color:$primary;
		padding: 0px;
		margin: 0px;

	}
	.dropdown-item {
		color: $white !important;
	 	font-weight: 500;
	 	font-size: 1.1em;
	 	padding:.25em;
	}
	.dropdown-item:hover {
	 	color: $white !important;
	 	background-color: $accent;
	}

	.navbar-toggler {
		color: $white;
		border-color: $white;
	}
}


@import 'https://fonts.googleapis.com/css?family=Cinzel:400,700,900';

h1, h2, h3, h4, h5, h6 {
	color: $secondary !important;
	font-family: 'Cinzel', serif !important;
}

h1, h2 {
	font-weight: 700 !important;
}

body {
	font-family: "Tahoma", "Veranda" !important;
}
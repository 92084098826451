.footer {
	.footer-content {
		
		 p {
		 	font-size: .8em;
		 	background-color: $primary;
			color: #ffffff;
		 	margin-bottom: 0px;
		 	text-align: center;

		 	a {
		 		color: #ffffff;
		 		text-decoration: none;
		 	}
		 	a:hover {
		 		text-decoration: none;
		 		color: #ffffff;
		 	}
		 }
	}
	
}
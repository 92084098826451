.news-list {
	background-color: none;
	padding-top: 1em;
	.news-row {
				

		.news-item {
			padding-top:1em;
			background-color: $white;
			box-shadow: 0 0 5px 0 #000;
			margin-bottom: 1em;
			h2 {
				
			}
		}
		
	}

	.news-pagination {
		text-align: center;

	}

	.news-link {
		color:inherit;
	}

	.news-link:hover {
		text-decoration: none;
		
	}

	
}

.news-article {
	padding-top:1em;
}

.news-single-article {
	margin: .5em;
	padding: .5em;
	background-color: $white;
	box-shadow: 3px 5px 8px -1px rgba( 15, 15, 15, .8 ), -3px 5px 8px -1px rgba( 15, 15, 15, .8 );
	
	.news-article-top_img {
		padding-bottom: 1em;
		width: 100%;
		text-align: center;
	}

	div.col-md-10 > p > img {
		width: 100%;
		text-align: center;
	}
}
.contact-container {
	padding-top: 1em;
	background-color: rgba(200,200,200,.9);
	padding: 1em;
	 
	.btn-primary {
		background-color: $primary;
		border-color: $primary;
	}

	.btn-primary:hover {
		background-color:$accent;
	}
}
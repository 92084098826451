@import "core/base.scss";


@import "components/header.scss";
@import "components/nav.scss";
@import "components/footer.scss";
@import "components/sidebar.scss";
@import "pages/home.scss";
@import "pages/news.scss";
@import "pages/contact.scss";

html {
	background: 
		linear-gradient(
			rgba(200,200,200,.05),
			rgba(200,200,200,.05)
		),
		url("../img/texture2.jpg");
}

body {
	//background-color: rgba(200,200,200,.3);
	background:none;
	padding-left: 0px !important;
	padding-right: 0px !important;

}
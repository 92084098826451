.sidebar {
	background-color: $primary;
	color: $white;
	margin-top: .5em;
	margin-bottom: .5em;
	text-align:center;
	padding-bottom: 1em;
	padding-top: 1em;

	hr {
		border-color: $white;
	}

	h4 {
		color: $white !important;
	}

	.patreon-button {
		.patreon-button-img {
			width:90%;
		}
	}

	.facebook-button-img {
		width: 90%;
	}

	.sidebar-link {
		img {
			margin-top: .5em;
		}
		
		
	}
	#mc_embed_signup form {
		text-align: center !important;
		input {
			width: 90% !important;
		}
		.button {
			
		}
	}

	.news-link {
		
	}

	.news-link:hover {
		text-decoration: none;
		.news-item {
			background-color: $accent;
			color: $white;
			
			h6 {
				color: $white !important;
			}

			p {
				color: $white;
			}
		}
	}

	.news-item {
		background-color: $white;
		box-shadow: 0 0 5px 0 #000;
		padding:.5em;
		margin-bottom: .5em;
		h6 {
			text-align: left;
		}
		p {
			text-align: left;
			font-size: .8em;
			color: black;
		}
	}

	.facebook-link {

	}

	.facebook-link:hover {
		div {
			background-color: $primary;
			h3 {
				color: $white !important;
			}
		}
		text-decoration: none;
	}
}
@import "color-swatches/black-white.scss";
@import "color-swatches/charcoal.scss";


$white: $true-white;
$black: $true-black;



/* set primary, secondary, tertiary, accent */

$primary: $dark-grey;
$secondary: $shale-grey;
$tertiary: $golden-brown;
$accent: $charcoal;
